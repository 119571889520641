import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, computed, onMounted } from 'vue'
import { capitalize } from '../utils'
import loading from '../utils/loading'

export function useDataLoader(categoryName) {
  const store = useStore()
  const router = useRouter()

  const tableData = computed(() => store.getters[`data/${categoryName}`])

  const title = computed(
    () =>
      store.getters['data/titles'].find(item => item.category === categoryName)
        ?.title
  )

  const ROUTE_NAME = {
    games: 'Game',
    music: 'Song',
    books: 'Book',
    films: 'Film'
  }

  const isLoading = ref(false)

  onMounted(async () => {
    isLoading.value = true
    if (!tableData.value.length) {
      loading.start()
      await store.dispatch(`data/get${capitalize(categoryName)}`)
      if (!title.value) {
        await store.dispatch('data/getTitles')
      }
      loading.stop()
    }
    isLoading.value = false
  })

  return {
    tableData,
    openElement: ({ id }) => {
      router.push({ name: ROUTE_NAME[categoryName], params: { id } })
    },
    title,
    isLoading,
    isDataNotLoaded: computed(() => this.isLoading.value && this.tableData.value.length)
  }
}
