<template>
  <app-card v-if="!isLoading && tableData.length">
    <div class="item-content" v-if="tableData.length">
      <div class="item-header">
        <a href="#" class="table-return-btn" @click.prevent="$router.go(-1)">
          <BackIcon/>
          Вернуться
        </a>
      </div>
      <h1 class="item-title">{{ item.name }}</h1>
      <div class="item-images">
        <img
          v-for="image in item.photos"
          class="item-img"
          :src="image"
          alt="img"
        >
      </div>
      <p class="item-description" v-html="item.description"></p>
    </div>

    <AppEmpty
      v-if="!tableData.length"
      title="Такого тут нет :("
    />
  </app-card>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useDataLoader } from '../use/data-loader'
import BackIcon from '../components/icons/BackIcon'
import AppEmpty from '../components/ui/AppEmpty'
import AppCard from '../components/ui/AppCard'

export default {
  name: 'Item',
  components: {
    BackIcon,
    AppEmpty,
    AppCard
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    const id = computed(() => route.params.id)
    const category = computed(() => route.meta.category)

    const item = computed(() => store.getters[`data/${category.value}`].find(item => item.id === id.value))
    const { isLoading, tableData } = useDataLoader(category.value)

    return {
      id,
      item,
      isLoading,
      tableData
    }
  }
}
</script>
